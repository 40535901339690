<template>
  <p :class="['urg-record', type === 'approve' ? 'urg-record-top' : '']">
    <img src="@/assets/images/urg-record-icon.png" v-if="type == 'staff'" />
    <span> {{ staffNameLen(recordInfo.name) }}发起了催办，已等待{{ recordInfo.info }}</span>
  </p>
</template>

<script setup>
defineProps(['recordInfo', 'type'])

function staffNameLen(name, len = 4) {
  if (name.length <= len) {
    return name
  }
  return name.substring(0, len) + '...'
}
</script>

<style lang='less' scoped>
.urg-record {
  img {
    width: 16px;
    height: 16px;
    vertical-align: bottom;
    margin-right: 4px;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
  }
}
.urg-record-top {
  text-align: center;
  line-height: 20px;
  padding-top: 6px;
}
</style>