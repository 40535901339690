<!--
 * @Author: chenxu chenxu@libawall.cn
 * @Date: 2023-04-20 14:21:12
 * @LastEditors: cyx 
 * @LastEditTime: 2024-01-27 15:27:38
 * @FilePath: \yda_web_manage\src\components\ApprovalFlow\index.vue
 * @Description:审批流全局组件
 * 
-->
<template>
  <div :class="['approval-flow', isApprove ? 'approve-box' : 'apply-box']">
    <div v-for="(item, index) in approvalInfo" :key="index" :class="[item.staffList?.length > 1 ? 'or-line-box' : '']">
      <div class="flex-layout-column">
        <div :class="['flow-single', item.staffList?.length > 1 ? 'border' : 'margin-top']">
          <template v-if="(!item.toPass && !item.toRefuse) || !item.toPassTwo">
            <div
              :class="[
                isApprove && ((item.staffList?.length > 1 && userIndex === 0) || index === 0)
                  ? 'approve-margin-left'
                  : '',
                isApprove && item.staffList?.length > 1 && userIndex === item.staffList.length - 1
                  ? 'last-child-width'
                  : ''
              ]"
              v-for="(user, userIndex) in item.staffList"
              :key="userIndex"
            >
              <!-- 头像行 -->
              <div class="head-picture-line">
                <div
                  class="head-picture"
                  :style="
                    `background-image: url(${
                      user.fullAvatar && user.fullAvatar.length !== 0 ? user.fullAvatar : defaultImage
                    })`
                  "
                ></div>
                <div
                  v-if="item.staffList?.length === 1 && index !== approvalInfo.length - 1"
                  :class="['line', !isApprove ? 'short' : '']"
                ></div>
                <template v-if="userIndex !== item.staffList.length - 1 && item.staffList.length > 1">
                  <span
                    v-if="item.mode === 'OR' || item.mode === 'AND'"
                    :class="[
                      'or',
                      isApprove && item.mode === 'OR' ? 'or-margin' : '',
                      isApprove && item.mode === 'AND' ? 'and-margin' : ''
                    ]"
                    >{{ item.mode === 'OR' ? '或' : '且' }}</span
                  >
                  <div
                    :class="['content-line', isApprove ? 'approve-line' : 'apply-line']"
                    v-if="item.mode === 'NEXT'"
                  ></div>
                </template>
              </div>
              <!-- 信息行 -->
              <div
                :class="[
                  'info-line',
                  !isApprove ? 'info-width' : '',
                  item.staffList?.length > 1 && isApprove && item.mode === 'OR' ? 'or-width' : ''
                ]"
              >
                <div :class="['name-box', item.staffList?.length > 1 && item.mode === 'OR' ? 'line-feed' : '']">
                  <a-tooltip
                    placement="bottomLeft"
                    v-if="(isApprove && user.nickname?.length > 5) || (!isApprove && user.nickname?.length > 3)"
                  >
                    <template #title>
                      <span>{{ user.nickname }}</span>
                    </template>
                    <div :class="['username', isApprove ? 'approve-name-length' : 'apply-name-length']">
                      {{ user.nickname }}
                    </div>
                  </a-tooltip>
                  <div v-else :class="['username', isApprove ? 'approve-name-length' : 'apply-name-length']">
                    {{ user.nickname }}
                  </div>
                  <!-- 审批人/撤销人审批状态 -->
                  <div
                    class="username"
                    v-if="isApprove && item.approvalType != 1 && !user.agreePass && !user.repeatPersonPass&&item.processMold==1"
                    :style="`color:${approvalStatus[user.approvalStatus].color}`"
                  >
                    （{{ approvalStatus[user.approvalStatus].name }}）
                  </div>
                  <div
                    class="username"
                    v-if="isApprove && item.approvalType != 1 && !user.agreePass && !user.repeatPersonPass&&item.processMold==2"
                    :style="`color:${archiveStatus[user.approvalStatus].color}`"
                  >
                    （{{ archiveStatus[user.approvalStatus].name }}）
                  </div>
                  <!-- 申请人审批状态 申请人,审批人,撤销人 -->
                  <div class="username approval-status" v-if="isApprove && item.approvalType == 1&&item.processMold==1">（发起申请）</div>
                  <div class="username approval-status" v-if="isApprove && item.approvalType == 1&&item.processMold==2&&!item.staffList[0].hasReArchive">（发起归档）</div>
                  <div class="username approval-status" v-if="isApprove && item.approvalType == 1&&item.processMold==2&&item.staffList[0].hasReArchive">（重新发起归档）</div>

                </div>
                <!--已审批/审批重复 -->
                <template v-if="user.agreePass && isApprove">
                  <div class="time" style="width: 90px">已审批，跳过</div>
                </template>
                <div v-else-if="user.repeatPersonPass && isApprove" class="time" style="width: 120px">
                  重复审批人，跳过
                </div>
                <template
                  v-else-if="isApprove && (item.mode !== 'OR' || (item.mode === 'OR' && item.staffList.length === 1))"
                >
                  <!-- 审批日期/时间 -->
                  <template v-if="user.approvalStatus != 1 && user.approvalStatus != 2">
                    <div class="time">{{ user.time?.split(' ')[0] }}</div>
                    <div class="time">{{ user.time?.split(' ')[1] }}</div>
                  </template>
                  <!-- 审批理由 -->
                  <template v-if="user.approvalStatus == 3 || user.approvalStatus == 4 || user.approvalStatus == 5">
                    <a-tooltip
                      :title="user.comments"
                      overlayClassName="tooltip-custom"
                      placement="bottom"
                      color="white"
                      v-if="user.comments && user.comments.length > 8"
                    >
                      <div class="reason">{{ user.comments }}</div>
                    </a-tooltip>
                    <div v-else class="reason">{{ user.comments }}</div>
                  </template>
                  <template v-if="user.addSignName != null">
                    <div class="add-sign">
                      <span>添加加签人：</span>
                      <div>
                        <span class="sign-name" v-if="user.addSignName.length < 4">{{ user.addSignName }}</span>
                        <span v-else>
                          <a-tooltip
                            placement="bottomLeft"
                            :overlayStyle="{'max-width': '280px'}"
                          >
                            <template #title>
                              <div>{{ user.addSignName }}</div>
                            </template>
                            <div class='apply-name-length'>{{ user.addSignName }}</div>
                          </a-tooltip>
                      </span>
                      </div>
                      <span v-if="user.addSignRemark?.length > 0">
                        <a-tooltip
                          placement="bottomLeft"
                          :overlayStyle="{'max-width': '280px'}"
                        >
                          <template #title>
                            <div>添加加签人：{{ user.addSignName }}</div>
                            <div>加签备注：{{ user.addSignRemark }}</div>
                          </template>
                          <!-- <div :class="['username', isApprove ? 'approve-name-length' : 'apply-name-length']"> -->
                            <!-- {{ user.nickname }} -->
                            <div class='apply-icon-length'>

                            <img src="@/assets/images/approval/pop.png" alt="" style="width: 16px;">
                          </div>
                        </a-tooltip>
                      </span>
                    </div>
                  </template>
                </template>
                <!-- 一个人催办 占位  等接口加判断-->
                <!-- <template v-if="user.approvalStatus == 2 && isApplyDetail && item.staffList?.length == 1">
                  <UrgButton @urgOk="handleUrgOk" v-if="item.urge" v-bind="urgListFormate(item.urgeList ?? [])" :processMold="item.processMold">
                  </UrgButton>
                  <UrgeRecord v-if="item.urgeRecord" :recordInfo="item.urgeRecord" type="approve"></UrgeRecord>
                </template> -->
              </div>
            </div>
          </template>
          <!-- 空节[自动通过/自动驳回] -->
          <!-- <template v-if="(item.toPass || item.toRefuse) && isApprove"> -->
          <template v-if="(item.toPass && item.toPassTwo) || item.toRefuse">
            <div :class="[index === 0 ? 'approve-margin-left' : '']">
              <div class="head-picture-line">
                <div class="head-picture" :style="`background-image: url(${automaticPassImage})`"></div>
                <div :class="['line', !isApprove ? 'short' : '']" v-if="index !== approvalInfo.length - 1"></div>
              </div>
              <div class="info-line">
                <div class="name-box">
                  <div class="username" v-if="item.toPass && item.toPassTwo">自动审批通过</div>
                  <div class="username" v-if="item.toRefuse">自动审批驳回</div>
                </div>
                <template v-if="item.staffList && item.staffList.length && item.toPassTwo">
                  <div class="time">{{ item.staffList[0].time && item.staffList[0].time.split(' ')[0] }}</div>
                  <div class="time">{{ item.staffList[0].time && item.staffList[0].time.split(' ')[1] }}</div>
                </template>
              </div>
            </div>
          </template>
        </div>
        <!-- 或签会签发起催办 占位 -->
        <!-- <template v-if="isApplyDetail && item.staffList?.length > 1">
          <UrgButton @urgOk="handleUrgOk" v-if="item.urge" v-bind="urgListFormate(item.urgeList ?? [])"  :processMold="item.processMold"></UrgButton>
          <UrgeRecord v-if="item.urgeRecord" :recordInfo="item.urgeRecord" type="approve"></UrgeRecord>
        </template> -->
      </div>
      <!-- 或签右侧线条展示 -->
      <div
        v-if="item.staffList?.length > 1 && index !== approvalInfo.length - 1"
        :class="['line or-line', !isApprove ? 'short' : '']"
      ></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, onUpdated, ref, inject, computed } from 'vue'
import automaticPassImage from '@/assets/images/automatic-pass.png'
import defaultImage from '@/assets/images/default-img.png'
import UrgButton from '@/components/UrgButton/index.vue'
import UrgeRecord from '@/components/UrgRecord/index.vue'
import { cmsNotice } from '@/utils/utils'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: { UrgButton, UrgeRecord },
  props: {
    approvalInfo: {
      type: Array,
      default: () => []
    },
    isApprove: {
      //审批|发起申请
      type: Boolean,
      default: false
    },
    isApplyDetail: {
      // 是否是用印申请页面
      type: Boolean,
      default: false
    },
  },
  emits: ['urgUpdate'],
  setup(props, { emit }) {
    const approvalStatus = {
      1: {
        name: '待审批',
        color: 'rgba(0, 0, 0, 0.25)'
      },
      2: {
        name: '审批中',
        color: 'rgba(255, 147, 0, 1)'
      },
      3: {
        name: '已同意',
        color: 'rgba(82, 196, 26, 1)'
      },
      4: {
        name: '已拒绝',
        color: 'rgba(245, 34, 45, 1)'
      },
      5: {
        name: '已撤销',
        color: 'rgba(245, 34, 45, 1)'
      },
      6: {
        name: '已结束',
        color: 'rgba(150, 151, 153, 1)'
      }
    }
    const archiveStatus = {
      1: {
        name: '归档待审批',
        color: 'rgba(0, 0, 0, 0.25)'
      },
      2: {
        name: '归档审批中',
        color: 'rgba(255, 147, 0, 1)'
      },
      3: {
        name: '归档同意',
        color: 'rgba(82, 196, 26, 1)'
      },
      4: {
        name: '归档拒绝',
        color: 'rgba(245, 34, 45, 1)'
      },
      5: {
        name: '已撤销',
        color: 'rgba(245, 34, 45, 1)'
      },
      6: {
        name: '已结束',
        color: 'rgba(150, 151, 153, 1)'
      }
    }
    const route = useRoute()

    const setStyle = () => {
      let first = document.getElementsByClassName('approve-margin-left')
      Array.from(first).forEach(element => {
        console.log('开始节点位置的元素', element)
        console.log('开始节点位置下的文本内容对象', element.getElementsByClassName('info-line'))
        let marginLeft = element.getElementsByClassName('info-line')[0].offsetWidth / 2 - 20
        element.style.marginLeft = `${marginLeft}px`
      })
      let last = document.getElementsByClassName('last-child-width')
      Array.from(last).forEach(element => {
        console.log('结束节点位置的元素', element)
        console.log('结束节点位置下的文本内容对象', element.getElementsByClassName('info-line'))
        let width = element.getElementsByClassName('info-line')[0].offsetWidth / 2 + 20
        element.style.width = `${width}px`
      })
    }

    onMounted(() => {
      setStyle()
      console.log('isApprove', props.isApprove)
      console.log('审批信息', props.approvalInfo)
    })
    onUpdated(() => {
      setStyle()
    })
    // 催办结果
    function handleUrgOk(data) {
      console.log('催办成功吗', data)
      setStyle()
    }
    // 催办数据格式化
    function urgListFormate(list) {
      if (list.length == 0) return
      const { stage } = list[0]
      const staffIds = list.map(item => item.staffId)
      const dataId = list.map(item => item.dataId)
      const obj = { stage, staffIds,dataId}
      return obj
    }
    return {
      automaticPassImage,
      defaultImage,
      approvalStatus,
      archiveStatus,
      setStyle,
      handleUrgOk,
      urgListFormate
    }
  }
})
</script>

<style lang="scss" scoped>
.approval-flow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: scroll;

  .flow-single {
    width: fit-content;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;

    .head-picture-line {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 4px;

      .head-picture {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: gray;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      .or {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        margin: 0 8px;
      }

      .or-margin {
        margin: 0 24px;
      }

      .and-margin {
        margin: 0 60px;
      }

      .content-line {
        height: 1px;
        background: #0A7BFF;
        border-radius: 6px;
        margin: 0 4px;
      }

      .approve-line {
        width: 126px;
      }

      .apply-line {
        width: 22px;
      }
    }

    .info-line {
      width: fit-content;
      max-width: 168px;
      transform: translateX(calc(-50% + 20px));
      display: flex;
      flex-direction: column;
      align-items: center;

      .name-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        white-space: nowrap;

        .username {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }

        .approval-status {
          color: #52c41a;
        }

        .approve-name-length {
          max-width: 5em;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .apply-name-length {
          width: 3em;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: center;
        }
      }

      .line-feed {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .time {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        line-height: 22px;
      }
      .add-sign {
        display: flex;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        line-height: 22px;
        .sign-name {
          max-width: 50px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-right: 4px;
        }
        .apply-name-length {
          line-height: 22px;
          max-width: 68px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          cursor: pointer;
        }
        .apply-icon-length {
          line-height: 26px;
        }
      }


      .reason {
        width: 8em;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
      }
    }

    .info-width {
      width: fit-content;
      margin-left: 0;
    }

    .or-width {
      width: fit-content;
      max-width: 70px;
      transform: translateX(calc(-50% + 20px));
      // margin-left: -15px;
    }

    .content-box {
      // margin-left: 15px;
    }

    .and-content-box {
      // margin-left: 57px;
    }

    .approve-margin-left {
      // margin-left: 57px;
    }

    .last-child-width {
    }
  }

  .border {
    border-radius: 2px;
    border: 1px dashed #e1e1e1;
    padding: 8px;
  }

  .line {
    width: 158px;
    height: 1px;
    background: #0A7BFF;
    border-radius: 6px;
    margin-left: 4px;
    margin-right: 4px;
  }

  .short {
    width: 40px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .or-line-box {
    display: flex;
    flex-direction: row;
  }

  .or-line {
    margin-top: 28px;
  }

  .margin-top {
    margin-top: 8px;
  }
}

.approve-box {
  padding: 11px 0 33px 16px;
  margin-right: 16px;
}

.apply-box {
  padding: 16px 16px 21px 0;
}
</style>
